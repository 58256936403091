@import url('https://fonts.googleapis.com/css2?family=Poppins&display=swap');

body {
  font-family: 'Poppins';
}

.header {
  background-color: #FFFFFF;
  z-index: 100;
  box-shadow: 0px 4px 10px rgba(120, 120, 120, 0.06);
  width: 100%;
  min-width: 100vw;
  margin-right: 10px;
}

.main {
  padding-top: 5px;
  background-color: #FFFFFF;
  min-height: calc(100vh - 10px);
  overflow-y: auto;
  padding-right: 10px;
  padding-left: 10px;
}

.color-grey {
  color: #CFCED5 !important;
}

.form-search {
  background-color: #F3F4F8 !important;
  border-radius: 8px;
  border: none;
  height: 44px;
}

.form-icon {
  width: 100%;
  margin-bottom: 10px;
}

.form-icon .material-icons {
  position: absolute;
  padding: 12px;
  color: #BFBFBF;
  width: 70px;
}

.form-control-icon {
  padding-left: 35px;
}

.footer {
  position: fixed;
  bottom: 0;
  width: 100%;
  min-width: 100vw;
  z-index: 100;
  min-height: 30px;
  background: #F3F4F8;
  border-radius: 14px 14px 0px 0px;
}

.icon-menu {
  color: #CFCED5 !important;
  font-size: 24px;
}

.icon-menu.active {
  color: #0d58e2 !important;
}

.footer.btn {
  text-align: center;
  padding: 0;
}

.btn.active {
  color: #0d58e2 !important;
}

.home-banner {
  max-height: 140px;
  width: 100%;
  border-radius: 8px;
}

.kategori-item {
  width: 64px;
  height: 64px;
  border-radius: 16px;
  background-color: #FFF5D1;
  margin-right: 8px;
}

.kategori-item img {
  width: 64px;
  height: 64px;
  object-fit: cover;
}

.nav-link {
  color: #000000 !important;
  /* border-bottom: solid 2px #7C7C7C !important; */
  border-radius: none;
}

.nav-link.active {
  background-color: transparent !important;
  border-bottom: solid 2px #0d58e2 !important;
  color: #000000 !important;
}

.div-cerita {
  min-width: 100%;
  max-width: 100%;
  overflow-x: auto;
  min-height: 300px;
  margin-top: 24px;
}

.card-cerita {
  max-width: 267px;
  min-width: 267px;
  min-height: 280px;
  background: #FFFFFF;
  box-shadow: 0px 4px 20px rgba(206, 206, 206, 0.5);
  border-radius: 12px;
}

.img-cerita {
  border-radius: 12px 12px 0 0;
  width: 100%;
  height: 135px;
  object-fit: cover;
}

.card-lokasi {
  min-width: 100%;
  max-width: 100%;
  overflow-x: auto;
  min-height: 80px;
}

.card-lokasi-item {
  background-color: #F3F4F8;
  border-radius: 32px;
  width: 150px;
  padding: 15px 15px 10px 15px;
}

.card-lokasi-item.active {
  background-color: #FCD900;
}

.card-acara {
  padding: 16px 18px 0px 18px;
  border-bottom: solid 2px #F4F4F4;
}

.card-campaign {
  min-height: 80px;
  background-color: #FFF5D1;
  border-radius: 8px;
  padding: 18px 12px 12px 12px;
  margin-bottom: 32px;
}

.material-icons {
  display: inline-flex;
  vertical-align: top;
}

.accordion-button {
  background-color: #F3F4F8 !important;
  margin-bottom: 5px;
}

.accordion-body {
  background-color: #F3F4F8 !important;
  padding: 10px;
}

.card-berita {
  background-color: #FFFFFF;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 10px;
}

.btn-default {
  background-color: #0d58e2;
  color: #fefefe;
  border-radius: 32px;
  min-height: 52px;
  margin-top: 24px;
  margin-bottom: 16px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
  white-space: nowrap;
}

.btn-icon {
  padding: 10px;
  margin: 0;
  background-color: #0d58e2;
  color: #fefefe;
}

.card-payment {
  padding: 10px 5px 10px 5px;
  border-radius: 6px;
  border: solid 1px #F3F4F8;
  color: #CFCED5;
  font-size: 16px;
}

.card-payment.active {
  background-color: #FCD900;
  color: #FFFFFF;
}

.form-check-input:checked {
  background-color: #0d58e2 !important;
  border-color: #0d58e2 !important;
}

.card-donatur {
  height: 87px;
  padding: 13px 12px 13px 12px;
  background-color: #FFFFFF;
  box-shadow: 0px 2px 20px rgba(214, 214, 214, 0.3);
  border-radius: 6px;
}

.card-kategori {
  background-color: #F3F4F8;
  border-radius: 32px;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 5px;
}

.card-kategori.active {
  background-color: #FCD900;
}

.card-explore {
  background: #FFFFFF;
  box-shadow: 0px 4px 15px rgba(212, 209, 209, 0.3);
  border-radius: 16px;
  min-height: 287px;
  margin-bottom: 15px;
  padding: 8px 8px 8px 8px;
}

.card-cari {
  height: 90px;
  padding: 5px;
  margin-bottom: 10px;
}

.offcanvas {
  z-index: 9999999999;
  height: 340px;
}

.card-orange {
  background: linear-gradient(107.41deg, #FDC830 -13.88%, #F37335 123.57%);
  border-radius: 16px;
  min-height: 91px;
  color: #FEFEFE;
  text-align: center;
  width: 180px;
  padding: 20px;
}

.card-biru {
  background: linear-gradient(109.47deg, #00C6FF -13.4%, #0072FF 118.33%);
  border-radius: 16px;
  min-height: 91px;
  color: #FEFEFE;
  text-align: center;
  width: 180px;
  padding: 20px;
}

.card-trans {
  background: #FFFFFF;
  border: 1px solid #F4F4F4;
  box-sizing: border-box;
  box-shadow: 0px 2px 10px rgba(208, 208, 208, 0.15);
  border-radius: 12px;
  padding: 10px
}

.bg-success {
  padding: 6px 10px 6px 10px;
  background: rgba(1, 135, 48, 0.25) !important;
  border-radius: 6px;
  color: #018730 !important;
}

.bg-danger {
  padding: 6px 10px 6px 10px;
  background: rgba(255, 39, 39, 0.25) !important;
  border-radius: 6px;
  color: #FF2727 !important;
}

.bg-warning {
  padding: 6px 10px 6px 10px;
  background: #0d58e2 !important;
  border-radius: 6px;
  color: #FFFFFF !important;
}

.card-detail-payment {
  background: #FFFFFF;
  border: 1px solid #F4F4F4;
  box-sizing: border-box;
  box-shadow: 0px 2px 8px rgba(208, 208, 208, 0.15);
  border-radius: 16px;
  padding: 18px;
}

.card-notif {
  background: #FFFFFF;
  box-shadow: 0px 2px 20px rgba(214, 214, 214, 0.3);
  border-radius: 8px;
  padding: 20px 10px 20px 10px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  color: #CFCED5 !important;
  opacity: 1;
  /* Firefox */
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  color: #CFCED5 !important;
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  color: #CFCED5 !important;
}

/* The snackbar - position it at the bottom and in the middle of the screen */
#snackbar {
  visibility: hidden;
  min-width: 250px;
  margin-left: -125px;
  background-color: #333;
  color: #fff;
  text-align: center;
  border-radius: 2px;
  padding: 16px;
  position: fixed;
  z-index: 9999999;
  left: 50%;
  bottom: 80px;
}

#snackbar.show {
  visibility: visible;
  -webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
  animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 80px;
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    bottom: 0;
    opacity: 0;
  }

  to {
    bottom: 80px;
    opacity: 1;
  }
}

@-webkit-keyframes fadeout {
  from {
    bottom: 80px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}

@keyframes fadeout {
  from {
    bottom: 80px;
    opacity: 1;
  }

  to {
    bottom: 0;
    opacity: 0;
  }
}




/* LOGIN */
.header-login {
  background-color: #0d58e2;
  min-height: 253px;
  max-width: 100%;
  border-radius: 0px 0px 30px 30px;
}

.card-form-login {
  background: #FFFFFF;
  box-shadow: 0px 4px 8px rgba(208, 208, 208, 0.25);
  border-radius: 12px;
  min-height: 360px;
  padding: 0 16px 32px 16px;
  text-align: center;
  position: absolute;
  top: 138px;
  width: 92%;
  left: 16px;
}

.btn-login {
  background-color: #0d58e2;
  color: #fefefe;
  border-radius: 32px;
  width: 100%;
  min-height: 52px;
  margin-top: 24px;
  margin-bottom: 16px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
}

.btn-login-icon {
  background-color: #F3F4F8;
  color: #202020;
  border-radius: 32px;
  width: 100%;
  min-height: 52px;
  margin-top: 24px;
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.01em;
}

.btn-nav {
  padding: 10px;
}

.form-control-verify {
  text-align: center;
}

.form-login {
  width: 100%;
  margin-bottom: 10px;
}

.form-login .material-icons {
  position: absolute;
  padding: 12px;
  left: 12px;
  color: #BFBFBF;
  width: 70px;
}

.form-control-login {
  padding-left: 35px;
}

.btn.focus,
.btn:focus {
  outline: none;
  box-shadow: none;
}

.btn:hover {
  outline: none;
  box-shadow: none;
}

.btn.active,
.btn:active {
  outline: none;
}

.btn.active.focus,
.btn.active:focus,
.btn.active:hover,
.btn:active.focus,
.btn:active:focus,
.btn:active:hover {
  outline: none;
  box-shadow: none;
}

.accordion-item {
  border: 0 solid rgba(0, 0, 0, .125);
}

.carousel.carousel-indicators li {
  background-color: red;
}

.carousel.carousel-indicators li.active {
  background-color: blue;
}

.tab-pane {
  margin-bottom: 50px;
  padding-top: 24px;
}

.table {
  font-size: 12px !important;
}

.form-group {
  margin-bottom: 10px !important;
}

.image-upload>input {
  display: none;
  text-align: center;
}

.card-bg {
  background-image: url("./card.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  color: #fefefe;
  width: 100%;
  min-height: 140px;
}